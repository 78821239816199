import { TinodeConnectionSetup } from 'tinode-sdk';

export const environment = {
	apiURL: 'https://ameetee.backend.develop.cloud.technokratos.com',
	tinode: {
		connection: {
			apiKey: 'AQEAAAABAAD_rAp4DJh05a1HAwFT3A6K',
			// host: 'localhost:6060',
			// host: 'tinode.cloud.technokratos.com',
			host: 'tinode.ameetee.cloud.technokratos.com',
			secure: true,
		} satisfies TinodeConnectionSetup,
	},
	userAgreement: 'https://minio.ameetee.backend.develop.cloud.technokratos.com/static/files/User_Agreement.pdf',
	privacyNotice: 'https://minio.ameetee.backend.develop.cloud.technokratos.com/static/files/Privacy_Notice.pdf',
	confidentialPolicy:
		'https://minio.ameetee.backend.develop.cloud.technokratos.com/static/files/Confidentiality_Policy.pdf',
	NDACompany:
		'https://minio.ameetee.backend.develop.cloud.technokratos.com/static/files/NDA%20buyer%20single%20company.pdf',
	NDAIndividual:
		'https://minio.ameetee.backend.develop.cloud.technokratos.com/static/files/NDA%20buyer%20single%20natural%20person.pdf',
};
